import React from "react"
import Portrait from "../../../types/potrait"
import useWindowSize, { breakPoints } from "../useWindowSize"
import Image from "gatsby-image"

interface Props {
  portrait: Portrait
  size?: "big" | "small"
}

const PortraitComponent: React.FC<Props> = ({ portrait, size = "small" }) => {
  switch (size) {
    case "big":
      return <PortraitBig portrait={portrait} />
    case "small":
      return <PortraitSmall portrait={portrait} />
  }
}

const PortraitSmall: React.FC<{ portrait: Portrait }> = ({ portrait }) => {
  const className = `flex items-center`
  const imageClassName = `rounded-full`
  return (
    <>
      {/* <Image className={`${className} hidden lg:block`} fixed={portrait.fixed250} /> */}
      <div className={`${className} hidden sm:block BIG`}>
        <Image className={imageClassName} fixed={portrait.fixed150} />
      </div>
      <div className={`${className} block sm:hidden SMALL`}>
        <Image className={imageClassName} fixed={portrait.fixed50} />
      </div>
    </>
  )
}

const PortraitBig: React.FC<{ portrait: Portrait }> = ({ portrait }) => {
  const className = `rounded-full border-solid border-white border-2 `
  const imageClassName = `rounded-full`
  return (
    <>
      <div className={`${className} hidden lg:block`}>
        <Image className={imageClassName} fixed={portrait.fixed600} />
      </div>
      <div className={`${className} hidden only-sm:block only-md:block`}>
        <Image className={imageClassName} fixed={portrait.fixed250} />
      </div>
      <div className={`${className} block sm:hidden`}>
        <Image className={imageClassName} fixed={portrait.fixed150} />
      </div>
    </>
  )
}

export default PortraitComponent
