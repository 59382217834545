import { useEffect, useState } from "react"

interface WindowSize {
  width: number
  height: number
}

function getWindow(): {
  innerHeight: number
  innerWidth: number
  scrollY: number
} {
  if (typeof window !== `undefined`) {
    return window
  } else {
    return {
      innerHeight: 667,
      innerWidth: 375,
      scrollY: 0,
    }
  }
}

function getWindowSize(): WindowSize {
  const { innerWidth: width, innerHeight: height } = getWindow()
  return {
    width,
    height,
  }
}

export function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

export const breakPoints = {
  sm: 640,
  // => @media (min-width: 640px) { ... }

  md: 768,
  // => @media (min-width: 768px) { ... }

  lg: 1024,
  // => @media (min-width: 1024px) { ... }

  xl: 1280,
}

export default useWindowSize
