import React from "react"
import Picture from "../../types/picture"
import Entry from "../../types/entry"
import { Link, graphql } from "gatsby"
import Portrait from "../../types/potrait"
import Story from "../../types/story"
import Image from "gatsby-image"
import useWindowSize, { breakPoints } from "../components/useWindowSize"
import TextContainer from "../components/elements/TextContainer"
import PortraitComponent from "../components/elements/Portrait"

interface Props {
  pageContext: {
    id: string
    next: string
    previous: string
  }
  data: {
    entry: Entry
  }
}

const EntryPage: React.FC<Props> = ({
  data: { entry },
  pageContext: { next, previous },
}) => {
  const { width } = useWindowSize()
  return (
    <React.Fragment>
      <Image className="hidden only-xs:block" fluid={entry.picture.fluid} />
      <div className="flex flex-col items-center mb-6">
        <div className="w-100 md:container flex items-center flex-col md:px-3 lg:px-6">
          <div className="mb-6 md:mb-8 w-100">
            {width >= breakPoints.md ? (
              <PictureFixed
                picture={entry.picture}
                alt={`${entry.author}: история Красного Мишки`}
              />
            ) : (
              ""
            )}
          </div>
          <TextContainer>
            <h1 className="mb-3 sm:mb-4 text-xl md:text-2xl text-center">
              <span className="font-bold">{entry.author},</span> {entry.city}
            </h1>
            <h3 className="text-gray600 text-sm text-center">
              {entry.material}
            </h3>
            {entry.storyTitle ? (
              <h2 className="mt-3 md:mt-4 text-xl md:text-2xl text-center">
                {entry.storyTitle}
              </h2>
            ) : (
              ""
            )}
          </TextContainer>
          <TextContainer
            html={entry.storyNode.childMarkdownRemark.html}
            className="my-4 md:my-6 story-container"
          ></TextContainer>
        </div>
        <PortraitComponent size="big" portrait={entry.portrait} />
      </div>
      {previous ? (
        <Link
          className="fixed flex h-screen top-0 fixed left-0 w-1/3 md:w-1/4 items-center justify-start text-2xl"
          to={previous}
        >
          <div className="absolute h-full w-full hover-left"></div>
          <div className="pl-3">&#8592;</div>
        </Link>
      ) : (
        ""
      )}
      {next ? (
        <Link
          className="fixed flex h-screen top-0 fixed right-0 w-1/3 md:w-1/4 items-center justify-end text-2xl"
          to={next}
        >
          <div className="absolute h-full w-full hover-right"></div>
          <div className="pr-3">&#8594;</div>
        </Link>
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

const PictureFixed: React.FC<{ picture: Picture; alt: string }> = ({
  picture,
  alt,
}) => {
  return (
    <React.Fragment>
      <div className="hidden only-xl-high:block">
        <Image fixed={picture.fixed900} alt={alt} />
      </div>
      <div className="hidden only-xl-wide:block">
        <Image fixed={picture.fixedMD} alt={alt} />
      </div>
      <div className="hidden only-lg:block">
        <Image fixed={picture.fixedMD} alt={alt} />
      </div>
      <div className="hidden only-sm:block only-md:block">
        <Image fixed={picture.fixed600} alt={alt} />
      </div>
    </React.Fragment>
  )
}

export default EntryPage

export const query = graphql`
  query($id: String) {
    entry: datoCmsEntry(id: { eq: $id }) {
      author
      city
      cityCoords {
        longitude
        latitude
      }
      country
      createdDate(formatString: "dd.MM.YYYY")
      firstName
      lastName
      patriName
      material
      picture {
        url
        fixed300: fixed(height: 300, width: 300) {
          ...GatsbyDatoCmsFixed
        }
        fixed450: fixed(height: 450, width: 450) {
          ...GatsbyDatoCmsFixed
        }
        fixed600: fixed(height: 600, width: 600) {
          ...GatsbyDatoCmsFixed
        }
        fixedMD: fixed(height: 768, width: 768) {
          ...GatsbyDatoCmsFixed
        }
        fixed900: fixed(height: 900, width: 900) {
          ...GatsbyDatoCmsFixed
        }
        fixed1200: fixed(height: 1200, width: 1200) {
          ...GatsbyDatoCmsFixed
        }
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      storyNode {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      portrait {
        url
        fixed50: fixed(height: 50, width: 50) {
          src
          tracedSVG
          srcSet
          sizes
          height
          base64
          aspectRatio
        }
        fixed100: fixed(height: 100, width: 100) {
          ...GatsbyDatoCmsFixed
        }
        fixed150: fixed(height: 150, width: 150) {
          ...GatsbyDatoCmsFixed
        }
        fixed250: fixed(height: 250, width: 250) {
          ...GatsbyDatoCmsFixed
        }
        fixed600: fixed(height: 600, width: 600) {
          ...GatsbyDatoCmsFixed
        }
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      storyTitle
      url
      id
    }
  }
`
